import React, { memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { TiDownload } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import LabelComponent from '../../../components/comman/Label/LabelComponent';
import LoadingOverlay from '../../../components/comman/Loading/LoadingOverlay';
import SearchWithDropdown from '../../../components/comman/Search/SearchWithDropdown';
import { capitalizeWords, downloadXlsx, formatDateToReadable } from '../../../extraStuff';
import { getRequest } from '../../../service/ApiEndpoint';

function MemberUserDateInfo() {
    // Export Excel
    const tableRef = useRef(null);

    const tableHead = ['S. No.', 'Thaali Size', 'First Name', 'Last Name', 'Area Name', 'Comments'];
    const { start } = useParams();
    const [users, setUsers] = useState([]);
    const [area, setArea] = useState([]);
    const filterBy = [
        { value: 'first_name', name: 'First Name' },
        { value: 'last_name', name: 'Last Name' },
        { value: 'size', name: 'Size' },
        { value: 'note', name: 'Comments' }
    ]

    // Searching
    const [keys, setKeys] = useState(['first_name'])
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (users.length > 0) {
        }
    }, [users]);

    const fetchArea = useCallback(async () => {
        try {
            const request = await getRequest('/area', {})
            const response = request?.data;
            if (response) {
                setArea(response)
            }
        } catch (error) {
            // console.error(error);
        }
    }, []);

    const fetchUsersWithDate = useCallback(async (date) => {
        if (!date) return false;
        try {
            const request = await getRequest(`/user/user-date/${date}`, {});
            const response = request?.data;

            // Ensure response is an array before setting users
            if (Array.isArray(response)) {
                setUsers(response);
            } else {
                setUsers([]); // Set empty array if response is not valid
            }
        } catch (error) {
            setUsers([]); // Set empty array on error
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // restors scroll position
        if (!start) return;
        fetchUsersWithDate(start);
        fetchArea();
    }, [fetchUsersWithDate, fetchArea, start]);

    // Searching Function
    const search = (data) => {
        if (!Array.isArray(data)) return [];

        if (!query) return data; // Return full data if no search query

        return data.filter((item) => {
            const matchesKeys = keys.some((key) => {
                if (!item[key]) return false; // Ensure the key exists in the item
                return item[key].toString().toLowerCase().includes(query.toLowerCase());
            });

            const matchesArea = item.areaId === Number(query);

            return matchesKeys || matchesArea;
        });
    };

    return (
        <section className='py-14 px-2'>
            <div className='max-w-screen-xl rounded-xl mx-auto border border-blue-gray-100 bg-white shadow-lg shadow-black/15 saturate-200 backdrop-blur-sm py-4 h-auto'>

                <label className='lg:text-4xl sm:text-3xl text-xl font-extrabold mx-4 flex justify-between items-center'>
                    <span className='text-[var(--table-text-blue2)]'>Members</span>
                    <span className='sm:text-lg text-xs bg-[var(--table-text-blue2)] text-white rounded-md py-1.5 px-2'>{formatDateToReadable(start)}</span>
                </label>

                <div className='motif-central'>&nbsp;</div>
                <div className='px-2 flex justify-between items-center gap-2'>

                    {/* Export Excel */}
                    <TiDownload
                        onClick={() => downloadXlsx(`${start}_admin_report`, tableRef)}
                        className='cursor-pointer text-2xl hover:text-3xl transition-all mx-4 text-[var(--table-text-blue2)]'
                        title='Download Report in Excel'
                    />

                    {/* Search Box */}
                    <SearchWithDropdown filterBy={filterBy} placeText={"Search Member . . ."} setKeys={setKeys} query={query} setQuery={setQuery} fetchArea={area} wantAreaSearch={true} />
                </div>

                <div className="relative overflow-x-auto shadow-md rounded-lg my-4 border-2 border-sec mx-2 bg-white">
                    {/* TABLE */}
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400" ref={tableRef}>

                        {/* TABLE HEAD */}
                        <thead className="text-xs uppercase bg-sec text-sec">
                            <tr>
                                {tableHead?.map((headItem, idx) => <th scope="col" className="pl-2 px-1 py-3 font-semibold" key={idx}>
                                    {headItem}
                                </th>)}
                            </tr>
                        </thead>

                        {/* TABLE BODY */}
                        <tbody>
                            {search(users)?.map((el, idx) =>
                                <tr key={el?.id} id={el?.id} className='hover:bg-[--table-bg-hoverBlue]'>
                                    <td className="px-2 py-1 font-medium base-text">
                                        {idx + 1}
                                    </td>
                                    <td className="px-2 py-1 base-text-secondary font-bold tracking-widest">
                                        {capitalizeWords(el?.size)}
                                    </td>
                                    <td className="px-2 py-1 font-bold tracking-wider base-text hover:text-green-500"
                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {capitalizeWords(el?.first_name)}
                                    </td>
                                    <td className="px-2 py-1 font-bold tracking-wider base-text hover:text-green-500"
                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {capitalizeWords(el?.last_name)}
                                    </td>
                                    <td className="px-2 py-1 base-text font-bold">
                                        {area?.map((ar) => (ar?.id === Number(el?.areaId)) && capitalizeWords(ar?.name))}
                                    </td>
                                    <td className="px-2 py-1 base-text font-bold">
                                        {el?.note}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className={`text-red-600 font-thin mx-4 sm:text-2xl text-xs ${search(users).length > 0 ? 'hidden' : 'block'}`}>No members have registered a thaali for this day.</div>

                {users.length > 0 &&
                    <Suspense fallback={<LoadingOverlay />}>
                        <LabelComponent labelName={`Displaying ${search(users)?.length} records`} labelNameClass={'m-4 text-xs base-text'} />
                    </Suspense>
                }
            </div >
        </section>
    )
}

export default memo(MemberUserDateInfo)