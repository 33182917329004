import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "sonner";
import store from "./app/store/store.js";
import LoadingOverlay from "./components/comman/Loading/LoadingOverlay.jsx";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import CoordinatorMemberUserDateInfo from "./Routes/Admin/Member/CoordinatorMemberUserDateInfo.jsx";
import MemberUserDateInfo from "./Routes/Admin/Member/MemberUserDateInfo.jsx";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Import Components
const BismillahNavbar = lazy(() =>
  import("./components/Navbar/BismillahNavbar.jsx")
);
const CoordinatorMembers = lazy(() =>
  import("./Routes/Coordinator/Members/CoordinatorMembers.jsx")
);
const Footer = lazy(() => import("./components/Footer/Footer.jsx"));
const MemberEdit = lazy(() => import("./Routes/Coordinator/MemberEdit.jsx"));
const Menu = lazy(() => import("../src/Menu/Menu.jsx"));
const Layout = lazy(() => import("../src/Routes/Layouts/Layout.jsx"));
const PushMenuPage = lazy(() =>
  import("./components/Calendar/PushMenuPage.jsx")
);
const PageNotFound = lazy(() =>
  import("./components/comman/PageNotFound/PageNotFound.jsx")
);

const Admin = lazy(() => import("./Routes/Admin.jsx"));
const AdminAddArea = lazy(() => import("./Routes/Admin/Area/AdminAddArea.jsx"));
const AdminArea = lazy(() => import("./Routes/Admin/Area/AdminArea.jsx"));
const AdminAddCoordinator = lazy(() =>
  import("./Routes/Admin/Coordinator/AdminAddCoordinator.jsx")
);
const AdminCoordinators = lazy(() =>
  import("./Routes/Admin/Coordinator/AdminCoordinators.jsx")
);
const AdminMembers = lazy(() =>
  import("./Routes/Admin/Member/AdminMembers.jsx")
);
const CoordinatorHomePage = lazy(() =>
  import("./Routes/Coordinator/CoordinatorHomePage.jsx")
);
const CoordinatorProfilePage = lazy(() =>
  import("./Routes/Coordinator/CoordinatorProfilePage.jsx")
);
const HomePage = lazy(() => import("./Routes/HomePage.jsx"));
const AdminProtectedLayout = lazy(() =>
  import("./Routes/Layouts/AdminProtectedLayout.jsx")
); // Import the custom route componen
const CoordinatorProtectedLayout = lazy(() =>
  import("./Routes/Layouts/CoordinatorProtectedLayout.jsx")
);
const MemberProtectedLayout = lazy(() =>
  import("./Routes/Layouts/MemberProtectedLayout.jsx")
);
const PublicProtectedLayout = lazy(() =>
  import("./Routes/Layouts/PublicProtectedLayout.jsx")
);
const Login = lazy(() => import("./Routes/Login.jsx"));
const MemberDefaultDaysForm = lazy(() =>
  import("./Routes/Member/MemberDefaultDaysForm.jsx")
);
const MemberHomePage = lazy(() => import("./Routes/Member/MemberHomePage.jsx"));
const MemberProfilePage = lazy(() =>
  import("./Routes/Member/MemberProfilePage.jsx")
);
const Register = lazy(() => import("./Routes/Register.jsx"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root?.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* TOASTER */}
        <Toaster
          richColors
          closeButton={"top-right"}
          expand={false}
          position="top-right"
          pauseWhenPageIsHidden
        />

        <Suspense fallback={<LoadingOverlay />}>
          <BismillahNavbar />
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* Public Routes */}
              <Route index element={<HomePage />} />
            </Route>

            {/* Users and Public Protected Route */}
            <Route path="/login" element={<PublicProtectedLayout />}>
              <Route index element={<Login />} />
            </Route>

            {/* Admin Protected Routes */}
            <Route path="/admin" element={<AdminProtectedLayout />}>
              <Route index element={<Admin />} />
              <Route
                path="member-date/:start"
                element={<MemberUserDateInfo />}
              />
              <Route path="register" element={<Register />} />
              <Route
                path="coordinator_master"
                element={<AdminCoordinators />}
              />
              <Route
                path="register/coordinator"
                element={<AdminAddCoordinator />}
              />
              <Route path="register/area" element={<AdminAddArea />} />
              <Route path="area_master" element={<AdminArea />} />
              <Route path="menu_master" element={<Menu />} />
              <Route path="push-menu" element={<PushMenuPage />} />
              <Route path="member_master" element={<AdminMembers />} />
            </Route>

            {/* Coordinator Protected Routes */}
            <Route path="/member" element={<MemberProtectedLayout />}>
              <Route index element={<MemberHomePage />} />
              <Route path="profile" element={<MemberProfilePage />} />
              <Route path="days" element={<MemberDefaultDaysForm />} />
            </Route>

            <Route path="/coordinator" element={<CoordinatorProtectedLayout />}>
              <Route index element={<CoordinatorHomePage />} />
              <Route
                path="member-date/:start/:areaId"
                element={<CoordinatorMemberUserDateInfo />}
              />
              <Route path="member" element={<CoordinatorMembers />} />
              <Route path="register" element={<Register />} />
              <Route path="member-edit/:id" element={<MemberEdit />} />
              <Route path="profile" element={<CoordinatorProfilePage />} />
            </Route>

            {/* Catch-All Route */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register(); // before

// Register the service worker and handle the update
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (window.confirm("A new version of this app is available. Update now?")) {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      window.location.reload();
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
