import { Typography } from '@material-tailwind/react';
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { capitalizeWords } from '../../../extraStuff';

function SearchWithDropdown({ filterBy, placeText, setKeys, query, setQuery, fetchArea, wantAreaSearch }) {
    const [show, setShow] = useState(false);

    return (
        <div className={`flex relative ${wantAreaSearch ? 'pb-6' : ''}`}>
            {wantAreaSearch &&
                <Typography variant='small' className='absolute bottom-0 right-0 font-medium base-text flex items-center gap-2'>
                    <input type="checkbox" name='areaId' id='areaFilter' onChange={() => {
                        setQuery('');
                        setShow(!show);
                    }} />
                    <label htmlFor='areaFilter' className='cursor-pointer'>
                        Search by Area Name
                    </label>
                </Typography>
            }
            <div className={`relative bg-white p-0.5 rounded-l-lg w-auto border-2 border-[var(--table-bg-blue)] border-r-0 ${show ? 'hidden' : 'block'}`}>
                <select name='base' className='min-w-min py-2.5 px-4 pr-6 text-sm font-medium text-center text-[var(--table-text-blue)] bg-[var(--table-bg-blue)] rounded-s-md hover:bg-[var(--table-bg-blue)] focus:ring-1 focus:outline-none focus:ring-white dark:bg-white dark:hover:bg-gray-600 dark:focus:ring-gray-700' defaultValue='name'
                    onChange={(e) => setKeys([e.target.value])}>
                    {filterBy?.map((el, idx) =>
                        <option value={el?.value} key={idx}>{el?.name}</option>
                    )}
                </select >
                <IoIosArrowDown className={`absolute right-2 top-[35%] bg-transparent border-none text-[var(--table-text-blue)]`} />
            </div>
            <div className={`w-full p-0.5 border-2 border-[var(--table-bg-blue)] ${show ? 'rounded-lg' : 'rounded-r-lg'}`}>
                <input type="search" name='inputQuery'
                    value={query}
                    className={`p-2.5 w-full z-20 text-sm text-[var(--table-text-blue)] rounded-e-md font-medium focus:ring-1 focus:outline-none focus:ring-white placeholder:text-[var(--table-bg-blue3)] tracking-wider ${show ? 'hidden' : 'block'}`}
                    placeholder={placeText} required onChange={(e) => setQuery(e.target.value)} />

                <select name='base' className={`min-w-min py-2.5 px-4 pr-6 text-sm font-medium text-center text-[var(--table-text-blue)] 
                bg-[var(--table-bg-blue)] rounded-md hover:bg-[var(--table-bg-blue)] focus:ring-1 focus:outline-none 
                focus:ring-white dark:bg-white dark:hover:bg-gray-600 dark:focus:ring-gray-700 ${show ? 'block' : 'hidden'}`} defaultValue='first_name'
                    onChange={(e) => setQuery(e.target.value)}>
                    <option value=''>Select Area Name</option>
                    {fetchArea?.map((el) => <option value={el?.id} key={el?.id}>{capitalizeWords(el?.name)}</option>)}
                </select >
            </div>
        </div>
    )
}

export default SearchWithDropdown