import React, { lazy, memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { TiDownload } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import LoadingOverlay from '../../../components/comman/Loading/LoadingOverlay';
import "../../../components/comman/TodayMenu/Toggle.css";
import { capitalizeWords, downloadXlsx, formatDateToReadable, formatTimeWithAmPm } from '../../../extraStuff';
import { getRequest, putRequest } from '../../../service/ApiEndpoint';

const LabelComponent = lazy(() => import('../../../components/comman/Label/LabelComponent'));
const SearchWithDropdown = lazy(() => import('../../../components/comman/Search/SearchWithDropdown'));

function CoordinatorMemberUserDateInfo() {
    // Export Excel
    const tableRef = useRef(null);

    const user = useSelector((state) => state.Auth.user);

    const [active, setActive] = useState(1);
    const tableHead = ['S. No.', 'Thaali Size', 'First Name', 'Last Name', 'Comments', 'Receiving', 'Last Edited'];
    const { start, areaId } = useParams();
    const [users, setUsers] = useState([]);
    const filterBy = [
        { value: 'first_name', name: 'First Name' },
        { value: 'last_name', name: 'Last Name' },
        { value: 'size', name: 'Size' },
        { value: 'note', name: 'Comments' }
    ]

    // Searching
    const [keys, setKeys] = useState(['first_name'])
    const [query, setQuery] = useState("");

    const fetchUsersWithDate = useCallback(async (date) => {
        if (!date) return false;
        if (!areaId) return false;

        try {
            const request = await getRequest(`/user/coordinator-user-date/${date}/${areaId}`, {});
            const response = request?.data;
            if (Array.isArray(response)) {
                setUsers(response);
            } else {
                setUsers([]);
            }
        } catch (error) {
            // console.error(error);
        }
    }, [areaId]);

    // Searching Function
    const search = (data) => {
        if (!Array.isArray(data)) return [];

        if (!query) return data; // Return full data if no search query

        return data.filter((item) => {
            const matchesKeys = keys.some((key) => {
                if (!item[key]) return false; // Ensure the key exists in the item
                return item[key].toString().toLowerCase().includes(query.toLowerCase());
            });

            const matchesArea = item.areaId === Number(query);

            return matchesKeys || matchesArea;
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0); // restors scroll position
        if (!start) return;
        fetchUsersWithDate(start);
    }, [fetchUsersWithDate, start]);

    const handleToggle = useCallback(async (item) => {
        if (!item) return false;
        try {
            const request = await putRequest(`/event/user-date/${item}`, {});
            const response = request?.data;
            if (response.message === 'Success') {
                toast.success('Updated Successfully');
                fetchUsersWithDate(start);
            }
        } catch (error) {
            // console.error(error);
        }
    }, [fetchUsersWithDate, start]);


    return (
        <section className='py-14 px-2'>
            <Suspense fallback={<LoadingOverlay />}>
                <div className='max-w-screen-xl rounded-xl mx-auto border border-blue-gray-100 bg-white shadow-lg shadow-black/15 saturate-200 backdrop-blur-sm py-4 h-auto'>

                    <label className='lg:text-4xl sm:text-3xl text-xl font-extrabold mx-4 flex justify-between items-center'>
                        <span className='text-[var(--table-text-blue2)]'>Members</span>
                        <span className='sm:text-lg text-xs bg-[var(--table-text-blue2)] text-white rounded-md py-1.5 px-2'>{formatDateToReadable(start)}</span>
                    </label>

                    <div className='motif-central'>&nbsp;</div>

                    <div className='px-2 flex flex-wrap justify-between items-center gap-2'>
                        <div className='flex items-center gap-4'>
                            {/* Active Redio Button */}
                            <div className="inline-flex items-center">
                                <label className="relative flex items-center cursor-pointer p-1 rounded-full">
                                    <input name="type" type="radio" htmlFor="radio" className="peer relative appearance-none w-5 h-5 border rounded-full border-blue-gray-200 cursor-pointer transition-all before:content[''] before:block before:bg-blue-gray-500 before:w-6 before:h-6 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity checked:border-[var(--table-text-blue2)] checked:before:bg-[var(--table-text-blue2)]"
                                        id="radio" checked={active} onChange={() => setActive(1)} />
                                    <span className="absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 pointer-events-none opacity-0 peer-checked:opacity-100 transition-opacity text-[var(--table-text-blue2)]"><svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor"><circle data-name="ellipse" cx="8" cy="8" r="8"></circle></svg></span>
                                </label>
                                <label className="text-[var(--table-text-blue)] font-medium select-none cursor-pointer mt-px" htmlFor="radio">Active</label>
                            </div>

                            {/* InActive Redio Button */}
                            <div className="inline-flex items-center">
                                <label className="relative flex items-center cursor-pointer p-1 rounded-full">
                                    <input name="type" type="radio" htmlFor="radioInactive" className="peer relative appearance-none w-5 h-5 border rounded-full border-blue-gray-200 cursor-pointer transition-all before:content[''] before:block before:bg-blue-gray-500 before:w-6 before:h-6 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity checked:border-[var(--table-text-blue2)] checked:before:bg-[var(--table-text-blue2)]"
                                        id="radioInactive" checked={!active} onChange={() => setActive(0)} />
                                    <span className="absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 pointer-events-none opacity-0 peer-checked:opacity-100 transition-opacity text-[var(--table-text-blue2)]"><svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor"><circle data-name="ellipse" cx="8" cy="8" r="8"></circle></svg></span>
                                </label>
                                <label className="text-[var(--table-text-blue)] font-medium select-none cursor-pointer mt-px" htmlFor="radioInactive">Inactive</label></div>
                        </div>

                        <div className='flex justify-between items-center gap-2'>
                            {/* Export Excel */}
                            <TiDownload onClick={() => downloadXlsx(`${areaId + "_" + user?.name}_${start}_report`, tableRef)}
                                className='cursor-pointer text-2xl hover:text-3xl transition-all mx-4 text-[var(--table-text-blue2)]'
                                title='Download Report in Excel'
                            />

                            {/* Search Box */}
                            <SearchWithDropdown filterBy={filterBy} placeText={"Search Member . . ."} setKeys={setKeys} query={query} setQuery={setQuery} wantAreaSearch={false} />
                        </div>
                    </div>

                    <div className="relative overflow-x-auto shadow-md rounded-lg my-4 border-2 border-sec mx-2 bg-white">
                        {/* TABLE */}
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400" ref={tableRef}>

                            {/* TABLE HEAD */}
                            <thead className="text-xs uppercase bg-sec text-sec">
                                <tr>
                                    {tableHead?.map((headItem, idx) => <th scope="col" className="pl-2 px-1 py-3 font-semibold" key={idx}>
                                        {headItem}
                                    </th>)}
                                </tr>
                            </thead>
                            {/* TABLE BODY */}
                            <tbody>
                                {search(users)?.filter(item => item.active === active)?.map((el, idx) =>
                                    <tr key={el?.id} id={el?.id} className='hover:bg-[--table-bg-hoverBlue]'>
                                        <td className="px-2 py-1 font-medium base-text">
                                            {idx + 1}
                                        </td>
                                        <td className="px-2 py-1 base-text-secondary font-bold tracking-widest">
                                            {capitalizeWords(el?.size)}
                                        </td>
                                        <td className="px-2 py-1 font-bold tracking-wider base-text hover:text-green-500"
                                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {capitalizeWords(el?.first_name)}
                                        </td>
                                        <td className="px-2 py-1 font-bold tracking-wider base-text hover:text-green-500"
                                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {capitalizeWords(el?.last_name)}
                                        </td>
                                        <td className="px-2 py-1 base-text font-bold min-w-[150px]">
                                            {el?.note}
                                        </td>
                                        <td className="px-2 py-1 base-text font-bold">
                                            <label className="switch shadow-md shadow-gray-700/50">
                                                <input type="checkbox" checked={el?.active} id="toggleSwitch" onChange={() => handleToggle(el?.id)} />
                                                <span className="slider">
                                                    <span className="text-dark">Yes</span>
                                                    <span className="text-light -ml-4">No</span>
                                                </span>
                                            </label>
                                        </td>
                                        <td className="px-2 py-1 text-gray-600 min-w-[150px]">
                                            {formatDateToReadable(el?.updatedAt.slice(0, 10))}
                                            {' '}
                                            {formatTimeWithAmPm(el?.updatedAt.slice(10))}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className={`text-red-600 font-thin mx-4 sm:text-2xl text-xs ${users.length > 0 ? 'hidden' : 'block'}`}>No members have registered a thaali for this day.</div>

                    {users.length > 0 && active === 1 ?
                        <LabelComponent labelName={`Displaying ${search(users)?.filter(item => item.active === 1)?.length} records`} labelNameClass={'m-4 text-xs base-text'} />
                        :
                        <LabelComponent labelName={`Displaying ${search(users)?.filter(item => item.active === 0)?.length} records`} labelNameClass={'m-4 text-xs base-text'} />
                    }
                </div>
            </Suspense>
        </section>
    )
}

export default memo(CoordinatorMemberUserDateInfo)